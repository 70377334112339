// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-features-presentation-slide-slide-template-jsx": () => import("/opt/build/repo/src/features/presentation/slide/slideTemplate.jsx" /* webpackChunkName: "component---src-features-presentation-slide-slide-template-jsx" */),
  "component---src-features-presentation-presentation-template-jsx": () => import("/opt/build/repo/src/features/presentation/presentationTemplate.jsx" /* webpackChunkName: "component---src-features-presentation-presentation-template-jsx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("/opt/build/repo/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("/opt/build/repo/src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lectures-jsx": () => import("/opt/build/repo/src/pages/lectures.jsx" /* webpackChunkName: "component---src-pages-lectures-jsx" */),
  "component---src-pages-workshops-index-jsx": () => import("/opt/build/repo/src/pages/workshops/index.jsx" /* webpackChunkName: "component---src-pages-workshops-index-jsx" */)
}

