import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';
import { breakpoints } from '../../../constants/style';
import { useState } from 'react';

const Header = styled('header')`
  background: rgb(236, 178, 98);
  color: rgb(33, 28, 74);
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  /* horizontal padding is entire width - main max width and it's padding*/
  padding: 0.5rem calc((100vw - 800px + 1rem) / 2);

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  /* for mobile */
  position: relative;

  nav {
    margin-top: 0;
    display: flex;
    align-items: center;
    transition: top 200ms ease;
    background-color: inherit;
    z-index: 10;

    @media only screen and (max-width: ${breakpoints.tablet}) {
      position: absolute;
      height: calc(100% + 3.5rem);
      flex-direction: column;
      top: ${({ isMenuVisible }) => (isMenuVisible ? '100%' : 'calc(-100% - 3.5rem)')};
      /* 0.5 rem to offset padding */
      width: calc(100% + 0.5rem);
      left: -0.5rem;
    }
  }

  .menuButton {
    margin-top: 0;
    background: top;
    border: 1px solid #231f49;
    border-radius: 20%;
    display: none;

    @media only screen and (max-width: ${breakpoints.tablet}) {
      display: block;
    }
  }
`;

const NavLink = styled(Link)`
  color: #222;
  font-size: 1rem;
  line-height: 1;
  margin: 0 0.5rem 0 0;
  padding: 0.25rem;
  text-decoration: none;

  &.current-page {
    border-bottom: 2px solid #222;
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding: 0.5rem;
  }
`;

const navigationRoutes = [
  { url: '/about', label: 'O mnie' },
  { url: '/blog', label: 'Blog' },
  { url: '/lectures', label: 'Prezentacje' },
  { url: '/workshops', label: 'Szkolenia' },
];

const HeaderComponent = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      logo: file(absolutePath: { regex: "/logo-color.png/" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const logo = data.logo.childImageSharp.fixed;

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);
  const closeMenu = () => setIsMenuVisible(false);

  return (
    <Header isMenuVisible={isMenuVisible}>
      <NavLink to="/" fontWeight="bold">
        <Image fixed={logo} alt="Logo" />
      </NavLink>
      <nav className="navigation">
        {navigationRoutes.map(({ label, url }, index) => (
          <NavLink to={url} onClick={closeMenu}>
            {label}
          </NavLink>
        ))}
      </nav>
      <button className="menuButton" onClick={toggleMenu}>
        Menu
      </button>
    </Header>
  );
};

export default HeaderComponent;
