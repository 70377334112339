export const breakpoints = {
  mobileSmall: '375px', // galaxy 6, iphone 6/7/8
  mobile: '415px', // iphone 6/7/8 Plus
  tablet: '768px', // iPad
  desktopSmall: '1024px', // small desktop screens
  desktop: '1280px', // laptop with MDPI screen
  desktopMac: '1366px', // laptop with MDPI screen
  desktopLarge: '1440px', // laptop with hDPI screen
  desktopLargest: '1680px',
  desktopMax: '1920px',
};
